//===== Page header (logo and top menu).


// Prevent site bounce.
:root {
  overscroll-behavior: none!important;
}

#header {
  //background-image: none;
}

#header {
  padding-top: 2.25rem;

  H1 {
    padding-bottom: 1rem;

    #logo {
      content: url("/images/ftc_logo_and_name.png");
      width: 440px;
    }
  }
}

@include breakpoint('<=wide') {
  #header {
    padding-top: 2rem;

    H1 {
      padding-bottom: 0.8rem;

      #logo {
        content: url("/images/ftc_logo_and_name.png");
        width: 400px;
      }
    }
  }
}

@include breakpoint('<=normal') {
  #header {
    padding-top: 1.8rem;

    H1 {
      padding-bottom: 0.7rem;

      #logo {
        content: url("/images/ftc_logo_and_name.png");
        width: 360px;
      }
    }
  }
}

@include breakpoint('<=narrow') {
  #header {
    padding-top: 1.5rem;

    H1 {
      padding-bottom: 0.5rem;

      #logo {
        content: url("/images/ftc_logo_and_name.png");
        width: 320px;
      }
    }
  }
}

#logo:link {
  border-bottom: none;
}



//===== Nav settings for top bar menu.

[data-visually-hidden="true"] {
  display: none;
}
[data-visually-hidden="false"] {
  display: block;
}

.navigation {
  padding-left: 0;
  list-style: none;
}
.navigation__item {
  position: relative;
}
.navigation__link {
  color: #c0c0c0;
}
.navigation__link:focus,
.navigation__link:hover,
.navigation__link:active {
  color: #fff;
}

.subnav {
  position: absolute;
  z-index: 1;
  font-size: 90%;
  font-weight: 100;
  background-color: #333;
  border-radius: _size(radius);
  color: #c0c0c0;
  white-space: nowrap;
  overflow: hidden;
  width: fit-content;
  padding: 0.5em 1em 0.5em 1em;
  text-align: left;
  box-shadow: 0 1em 1em 0 rgba(0,0,0,0.5);
  list-style: none;
}
.subnav__link {
  display: block;
  color: #c0c0c0;
  width: fit-content;
  border: 0;
}
.subnav__link:focus,
.subnav__link:hover,
.subnav__link:active {
  color: #fff;
}

//===== Sidebar menu customization.
.side-navigation {
  list-style: none;
}
.side-navigation__item {
  position: relative;
  padding: 0.5em 1em 0.5em 1em;
}
.side-navigation__link {
  color: #333;
  border-bottom: none;
}
.side-navigation__link:focus,
.side-navigation__link:hover,
.side-navigation__link:active {
  color: #81C4F7;
}

.side-subnav {
  position: absolute;
  left: 100%;
  z-index: 1;
  top: -0.5em;
  font-size: 90%;
  font-weight: 100;
  border-radius: _size(radius);
  color: #333;
  background-color: #fff;
  white-space: nowrap;
  overflow: hidden;
  padding: 0.5em 1em 0.5em 1em;
  text-align: left;
  border: 1px solid #474747;
  list-style: none;
  @include breakpoint('<=narrower') {
    position: static;
  }
}

.side-subnav__item {
  padding: 0.5em 1em 0.5em 1em;
}
.side-subnav__link {
  display: block;
  color: #333;
  width: fit-content;
  border: 0;
}
.side-subnav__link:focus,
.side-subnav__link:hover,
.side-subnav__link:active {
  color: #81C4F7;
}

// Include a search icon and menu icons on narrow/mobile displays.
#titleBar {
  .mobileSearchIcon {
    position: fixed;
    top: 0;
    right: 1em;
    z-index: 2;

    A {
      border-bottom: none;
    }
  }

  .menuIcon {
    @include icon(false, solid);
    border: 0;
    height: 60px;
    left: 0;
    position: absolute;
    top: 0;
    width: 80px;
    z-index: 2;

    &:before {
        display: block;
        height: 44px;
        line-height: inherit;
        text-align: center;
        width: 44px;
        color: #fff;
        opacity: 0.5;
    }


    &:active {
        &:before {
            opacity: 0.75;
      }
    }
  }

  .menuOpen {
    display: flex;

    &:before {
        content: '\f0c9';
    }
  }

  .menuClose {
    display: none;

    &:before {
        content: '\2715';
    }
  }
}

.mm-wrapper--opened #titleBar {
  .menuOpen {
    display: none;
  }

  .menuClose {
    display: flex;
  }
}

// Fix for the "dotted-line issue" in the mobile menu.
.mm-menu {
  top: 48px;

  A {
      border-bottom: none;
    }
}

// Removes the grid lines in the mobile menu.
.mm-listitem::after {
  display: none;
}
.mm-listitem__btn:not(.mm-listitem__text) {
  border-left-width: 0;
}


//===== Homepage.

// Video banner.
#featured_video {
  line-height: 0;
  video {
    width: 100%;
  }
}

// Text banner across bottom of video.
#banner {
  background-image: none;
  height: auto;

  HEADER {
    // Put the banner *below* the video. Otherwise, it competes too much with
    // the text that's embedded within the video.
    position: static;
    background: rgb(27,27,27);

    @include breakpoint('<=narrow') {
      font-size: normal;
      padding: 1em;
    }
    @include breakpoint('<=mobile') {
      padding: 0.75em;
    }
  }
}


// Programs FTC offers.
DIV.programs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-top;
  margin-left: 2em;
  margin-right: 2em;

  &> DIV.program {
    width: 300px;
    @include breakpoint('<=narrow') {
      width: 250px;
    }
    @include breakpoint('<=mobile') {
      width: 220px;
    }

    // Add a hover effect.
    color: rgb(33, 37, 41);
    margin: 1em;
    transition: transform .2s;
    &:hover {
      color: black;
      transform: scale(1.01);
      box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
    }

    A {
      border-bottom: none;
    }

    DIV.programHeading {
      line-height: 0;

      A {
        display: block;
        position: relative;

        color: white;

        H3 {
          background-color: #0074d2b3;
          text-decoration: none;
          font-size: 110%;
          text-align: center;
          line-height: normal;
          margin: 0;
          padding: 0.4em;
          width: 100%;

          position: absolute;
          bottom: 0;
        }

        IMG {
          width: 100%;
          height: auto;
          margin: 0;
          padding: 0;
        }
      }
    }

    DIV.programBlurb {
      line-height: normal;
      font-size: 90%;
      margin: 0.5em 0.3em 0.5em 0.3em;

      DIV.programMoreInfo {
        display: inline;
        text-align: right;
        white-space: nowrap;
      }
    }
  }
}


// "Contact us" panel.
DIV.contact_us_logo_container {
  display: flex;
  align-items: center;
  column-gap: 15vw;

  > DIV {
    &:first-child {
      text-align: right;
    }
  }
}
#contact_us_logo {
  width: 175px;
}
@include breakpoint('<=wide') {
  #contact_us_logo {
    width: 150px;
  }
}
@include breakpoint('<=normal') {
  DIV.contact_us_logo_container {
    column-gap: 10vw;
  }
  #contact_us_logo {
    width: 120px;
  }
}
@include breakpoint('<=narrow') {
  DIV.contact_us_logo_container {
    column-gap: 5vw;
  }
  #contact_us_logo {
    width: 80px;
  }
}
@include breakpoint('<=mobilep') {
  DIV.contact_us_logo_container {
    display: block;
    text-align: center;
  }
  DIV.contact_us_logo_container > DIV:first-child {
    display: none;
  }
}



//===== Tables.

table {
  background-color: transparent;
  width: 100%;
  margin-bottom: 15px;
  font-size: 0.9em;
  border-spacing: 0;
  border-collapse: collapse;
}

table td:first-child, table th:first-child {
  padding-left: 10px;
}

table th,
table td {
  padding: 5px;
  padding-left: 10px;
  line-height: 1.5;
  vertical-align: top;
  border: 1px solid #cccccc;
}

table th {
  font-weight: 500;
}

table thead th,
table tfoot th {
  font-size: 1em;
}

table caption + thead tr:first-child th,
table caption + thead tr:first-child td,
table caption + thead tr:first-child th,
table caption + thead tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + thead tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + thead tr:first-child td,
table thead:first-child tr:first-child th,
table thead:first-child tr:first-child td,
table thead:first-child tr:first-child th,
table thead:first-child tr:first-child td {
  border-top: 1px solid #cccccc;
}

table tbody > tr:nth-child(odd) > td,
table tbody > tr:nth-child(odd) > th {
  background-color: #f7f7f7;
}

table tbody tr:hover > td,
table tbody tr:hover > th {
  background-color: #e9e9e9;
}

table tbody + tbody {
  border-top: 2px solid #cccccc;
}

@include breakpoint('<=narrower') {
  table table {
    font-size: 0.8em;
  }

  table table th,
  table table td {
    padding: 7px;
    line-height: 1.3;
  }

  table table th {
    font-weight: 400;
  }
}



//===== Breadcrumbs.
// Reference: https://dongheenam.info/posts/breadcrumb-navigation-in-hugo/

OL.breadcrumbs {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  padding-left: 0;
  font-size: 80%;

  LI {
    display: inline;
    white-space: nowrap;
  }

  LI + LI:before {
    content: "\203A";
    padding: 0.3rem;
  }
}



//===== FT images.

.image.featured {
  IMG {
    // The theme specifies `width: 100%`, but we need `height: auto` also to avoid image distortion.
    // Reference: https://www.smashingmagazine.com/2020/03/setting-height-width-images-important-again/
    height: auto;
  }
}

IMG.ftimage {
  padding: 0;

  &.center_30 {
    width: 30%;
    margin: 1em auto 1em auto;
  }

  &.right_30 {
    float: right;
    width: 30%;
    margin: 1em 0 1em 1em;
  }

  &.right_40 {
    float: right;
    width: 40%;
    margin: 1em 0 1em 1em;
  }
}






//===== Miscellaneous tweaks.

// Change the top menu's accent color.
#nav {
  &:after {
    background-color: #81C4F7;
  }

  ul {
    > li {
      &.current {
        &:before {
          background-color: #81C4F7;
        }
      }
    }
  }
}

// Fix for mobile nav long text wraparound.
#navPanel {
  .link {
    height: auto;
  }
}

// Adjust vertical spacing between paragraphs.
p {
  margin-top: 1.0rem;
  margin-bottom: 1.0rem;
}
h1 {
  //font-size: 1.5em;
  //font-weight: 500;
  //color: #1d6172;
  margin-top: 28px;
  margin-bottom: 14px;
}
h2 {
  //font-size: 1.4em;
  //font-weight: 450;
  //color: #106e7c;
  margin-top: 24px;
  margin-bottom: 12px;
}
h3 {
  //font-size: 1.3em;
  //font-weight: 400;
  //color: #00898a;
  text-decoration-line: underline;
  margin-top: 22px;
  margin-bottom: 10px;
}
h4 {
  font-size: 1.40em;
  font-weight: 600;
  //font-style: italic;
  //color: #089f8f;
  margin-top: 20px;
  margin-bottom: 10px;
}
h5 {
  font-size: 1.30em;
  font-weight: 600;
  //color: #39b48e;
  margin-top: 20px;
  margin-bottom: 8px;
}
h6 {
  font-size: 1.15em;
  font-weight: 600;
  //color: #64c987;
  margin-top: 20px;
  margin-bottom: 6px;
}


// Adjust colors in the 'call to action'.
#cta {
  background-color: #0c30b4;
  color: white;

  .button {
    background-color: #81C4F7;
  }
}


// Use a nicer color for links.
A {
  color: #2082BF;
}

// For hiding artificial table captions
caption.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
